<template>
    <section class="cream-background">
    <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <h1>Boutique</h1>
                </div>
            </div>
            <div class="row computer">
                <div class="col-md-12">
                    <div class="d-flex justify-content-end">
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <th>Produits</th>
                                <th>description</th>
                                <th class="text-right">Quantité</th>
                                <th class="text-right">Prix Unitaire</th>
                                <th class="text-right">Prix</th>
                                <th class="text-right">Action</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item, itemid) in cart" :key="itemid">
                                    <td>{{item.name}}</td>
                                    <td>{{item.description}}</td>
                                    <td class="text-right">{{item.quantity}}</td>
                                    <td class="text-right">{{roundNumber(item.price * 1.2)}}€</td>
                                    <td class="text-right">{{item.totalp}} €</td>
                                    <td class="text-right"><i class="fa fa-trash pointer" @click="removeProductToCart(item)"></i></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <th>Total</th>
                                <th></th>
                                <th></th>
                                <th style="text-align:right"></th>
                                <th style="text-align:right">{{roundNumber(totalpice)}} €</th>
                                <th class="text-right">Prix total</th>
                            </tfoot>
                        </table>
                    </div>

                    <!-- Modal de validation-->
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-primary" v-if="$store.state.cartproduct.products.length>0"  @click="getMyInfo()" v-b-modal.buy>Valider l'achat</button>
                    </div>

                    <!-- modal achat -->
                    <b-modal id="buy" size="md" hide-footer hide-header>
                        <div v-if="loadermyinfo" class="jumping-dots-loader">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <form v-else class="forms-sample" @submit.prevent="handleSubmit">
                            <div class="row">
                                <!-- informations clients -->
                                <div class="col-md-12">
                                    <h4>Information du client</h4>
                                    <!-- nom et prénoms -->
                                    <b-form-group label="Nom et prénoms" label-for="Nom">
                                        <b-form-input v-model="name"
                                        type="text" id="Nom"
                                        :state="$v.name.$dirty ? !$v.name.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                        placeholder="Nom et prénoms" size="lg"></b-form-input>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                    </b-form-group>
                                    <!-- Adresse -->
                                    <b-form-group label="Adresse" label-for="address">
                                        <b-form-input
                                        v-model="line1" type="text"
                                        :state="$v.line1.$dirty ? !$v.line1.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                        id="address" placeholder="Adresse" size="lg"></b-form-input>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                    </b-form-group>

                                    <!-- Adresse -->
                                    <b-form-group label="Adresse" label-for="address">
                                        <b-form-input
                                        v-model="state" type="text"
                                        :state="$v.state.$dirty ? !$v.state.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                        id="address" placeholder="Adresse" size="lg"></b-form-input>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                    </b-form-group>

                                    <!-- Ville -->
                                    <b-form-group label="Ville" label-for="city">
                                        <b-form-input
                                        v-model="city"
                                        :state="$v.city.$dirty ? !$v.city.$error : null"
                                        aria-describedby="input-1-live-feedback"
                                        type="text" id="city" placeholder="Ville" size="lg"></b-form-input>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                    <!-- pays -->
                                    <b-form-group label="country" label-for="country">
                                        <b-form-select  id="country" name="countryAddress" v-model="country"
                                            :state="$v.country.$dirty ? !$v.country.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            class="mb-3">
                                                <b-form-select-option
                                                v-for="(country,country_id) in countriesSortedByName" :key="country_id"
                                                :value="country"
                                                >
                                                {{country}}
                                                </b-form-select-option>
                                            </b-form-select>
                                        <!-- <b-form-input
                                        v-model="country"
                                        :state="$v.country.$dirty ? !$v.country.$error : null"
                                        aria-describedby="input-1-live-feedback"
                                        type="text" id="country" placeholder="country" size="lg"
                                        
                                        ></b-form-input> -->
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                    <!-- Code postal -->
                                    <b-form-group label="Code postal" label-for="zipCode">
                                        <b-form-input
                                        v-model="postal_code"
                                        :state="$v.postal_code.$dirty ? !$v.postal_code.$error : null"
                                        aria-describedby="input-1-live-feedback"
                                        type="text" id="postal_code" placeholder="Code postal" size="lg"></b-form-input>
                                        <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                </div>
                                
                            </div>
                        <hr>
                        <div class="d-flex justify-content-end">
                            <span class="mx-2 btn btn-link" @click="$bvModal.hide('buy')">Annuler</span>
                            <template  v-if="deliver=== false" >
                                <button class="btn btn-gradient-primary" type="submit">Valider la commande</button>
                            </template>
                            <template v-else>
                                <button class="btn btn-gradient-primary" type="submit">Vérifier la commande</button>
                            </template>
                        </div>
                    </form>
                    </b-modal>

                    <!-- sélection de la livraison -->
                    <b-modal id="shipping" hide-footer no-close-on-backdrop no-close-on-esc hide-header-close>
                        <h6>Mode de récupération</h6>
                        <!-- Mode de récupération-->
                        <select v-model="deliver" class="custom-select custom-select-md" aria-label="Default select example">
                            <option :value="false">Récupérer à la boutique</option>
                            <option :value="true">Livrer</option>
                        </select>
                        
                        <!-- sélectionner le livreur -->
                        <div v-if="deliver">
                            <h6 class="mt-2">Choisir le livreur</h6>
                            <span v-if="!shippings" class="text-muted">
                                En cours de chargement ...
                            </span>
                            <select v-model="selectedshipping" class="custom-select custom-select-md" aria-label="Default select example">
                                <option :value="shipping" v-for="shipping in shippings" :key="shipping.index">{{shipping.shipping_provider}}</option>
                            </select>
                        </div>
                        <hr class="mt-5">
                        <span class="d-flex justify-content-between">
                            <h6>Prix de livraison</h6>
                            <h3 v-if="!deliver">0 €</h3>
                            <h3 v-else>{{selectedshipping.price}}  €</h3>
                        </span>
                        <div v-if="loadermyinfo" class="jumping-dots-loader">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-link" @click=" $bvModal.hide('shipping'), deleteMyorders()">Annuler</button>
                            <button class="btn btn-primary" @click="validateMyorder(), loadershow=true">Valider</button>
                        </div>
                    </b-modal>
                </div>
            </div>
            
            <!-- mobile -->
            <div class="row mobile">
                <div class="col-md-12">
                    <div class="card" v-for="(item, itemid) in cart" :key="itemid">
                    <label title="En construction" class="badge badge-gold construct" style="z-index:1" @click="removeProductToCart(item)"><i class="fa fa-trash"></i></label>
                        <div class="card-body">
                            <picture v-if="item.images.length>0">
                                <source class="" :srcset="item.images[0].content.webpImage" type="image/webp">
                                <img class="" :src="item.images[0].content.originalImage" alt="Card image cap">
                            </picture>
                            <img v-else class="" src="@/assets/images/import/default-image.jpg" alt="image">
                            <h3>{{item.name}}</h3>
                            <p class="text-muted">{{item.description}}</p>
                            <div class="d-flex justify-content-between">
                                <p>Quantité: {{item.quantity}}</p>
                                <span class="d-flex justify-content-end flex-column">
                                    <p>Prix Unitaire: {{item.price * 1.2}} €</p>
                                    <p>Prix: {{item.totalp}} €</p>
                                </span>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                    <h3 class="text-center">Prix Total Achat: {{totalpice}} €</h3> 
                </div>
                <div class=" col-md-12 flex text-center justify-content-center">
                    <button class="btn btn-gradient-primary" @click="getMyInfo()" v-b-modal.buy>Valider l'achat</button>
                </div>
            </div>

            <!-- informations -->
            <div class="row mt-5">
                <div class="col-md-12">
                    <h4>Pour toute demande d'information</h4>
                    <div class="row">
                        <div class="col-md-4 ">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex flex-row align-items-top">
                                        <i class="mdi mdi-facebook icon-md gold-yellow"></i>
                                        <div class="ml-3">
                                            <h6 class="gold-yellow pointer" @click="facebook()">Mon échappée belle</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                <div class="d-flex flex-row align-items-top">
                                    <i class="mdi mdi-phone icon-md gold-yellow"></i>
                                    <div class="ml-3">
                                    <h6 class="gold-yellow">06 75 43 75 22</h6>
                                    <p class="mt-2 text-muted card-text"></p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="card">
                                <div class="card-body">
                                <div class="d-flex flex-row align-items-top">
                                    <i class="mdi mdi-instagram text-facebook icon-md gold-yellow"></i>
                                    <div class="ml-3">
                                    <h6 class="gold-yellow pointer" @click="instagram()">@monechappebelle81</h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import {mapState} from 'vuex';
import axios from 'axios';
import countriesJson from '../json/countries'
import { validationMixin } from 'vuelidate';
import { required, minLength,maxLength} from 'vuelidate/lib/validators'
import pageLoader from '../components/pageLoader.vue';
import { priceMixin } from './mixins/price';


export default {
    name: 'shop',
    mixins: [validationMixin, priceMixin],
    components : {
        pageLoader
    },
    data () {
        return {
            ulidMyOrders:'',
            loadershow:false,
            loadermyinfo:false,
            cart: [
                ],
            search:'',
            name:'',
            line1:'',
            line2:'',
            city:'',
            state:'',
            country :'',
            postal_code:'',
            deliver:false,
            shippingproviders:'',
            shippings:'',
            selectedshipping:''
            }
    },
    computed:{
        // récupérer les noms des pays en français et trier par nom
        countriesSortedByName(){
            let countries_fr = countriesJson.map(country => country.name_fr).sort()
            return countries_fr
        },
        ...mapState({
            product: state=> state.cartproduct.products
        }),
        totalpice(){
            let total = 0
            this.cart.forEach(element => {
                total = total + ((element.price * 1.2) * element.quantity)
            });
            return total
        }
    },
    validations: {
        name: {
        required,
        minLength: minLength(4)
        },
        line1: {
        required,
        maxLength: maxLength(50),

        },
        line2:{

        },
        state:{
            required
        },
        city: {
        required
        },
        country: {
        required
        },
        postal_code: {
        required
        },
    },
    methods: {
        disabledDate (date) {
            let today = new Date()
            // let shippingMinDate= new
            return date < today.getDate()+10
        },
        //achat
        async handleSubmit() {
            this.buyProduct()
        //     console.log("submit buying");
        // this.$v.$touch()
        //     if (this.$v.$anyError) {
        //     return
        // }else{
        //     this.$bvModal.hide('buy')
        //     this.loadershow=true
        //     let validate = this.zipcodeValidation()
        //     if (validate) {
        //         this.buyProduct()
        //     }else{
        //     this.$snotify.warning("le produit n'est pas disponible dans votre pays!")
        //     this.loadershow=false
        //     }
        // }
        },
        // Vérification du zip_code (si le produit est autorisé)
        zipcodeValidation(){
            let validate = true;
            this.cart.forEach(element => {
                if(element.shippinglocation == "any"){
                    validate = true
                }else{
                    if (element.shippinglocation.substring(0,2) != this.zipcode.substring(0,2)) {
                        validate = false
                    }
                }
            });
            return validate
        },

        buyProduct () {
            //post myorder
            let body = {
                    "deliveryinfos":{
                        "name":this.name,
                        "street1":this.line1,
                        "city":this.city,
                        "zip":this.postal_code,
                        "state":this.state,
                        "country":this.country
                    },
                    "shippingprovider":"colissimo",
                    "shippingprice":5,
                    "datedelivery":this.dateString(this.datePlusDay(7)),
                    "status":"Préparation",
                    "dateorder":this.dateString()
            }  
            axios.post('myorders',body)
                .then(
                    resorder => {
                        this.loadershow= true
                        this.ulidMyOrders=resorder.data.order
                        this.postMypurchase(resorder.data.order)
                    }
                )
                .catch(
                    errorder => {
                        console.log(errorder)
                    }
                )
        
            
        },
        async postMypurchase(order){
            let request = []
            //post mypurchases
             this.cart.forEach(element => {
                request.push(axios.post('mypurchases',this.purchasesBody(element,order))
                .then(
                console.log('postmypurchase')
                )
                .catch(
                    err => {
                        console.log({err});
                        if(err.response.data=="Stock inferior!"){
                            this.$swal({
                                type: 'error',
                                title: 'Oups!',
                                text: 'Le stock des produits que vous avez acheté n\'est plus suffisant, Veuillez revérifier la disponibilité des produits.Merci',
                            })
                            
                            this.deleteMyorders()
                            this.$bvModal.hide('shipping')
                            this.loadershow=false
                        }
                        // this.$snotify.warning(err.response.data);
                    }
                ))
            });
            await Promise.all(request)
            .then()
            .catch(()=>{return})
            await this.shipping(order)
        },
        purchasesBody(purchases,order){
            //Dynamic body for mypurchases*
            return {
                order:order,
                product:purchases.ulid,
                quantity:Number(purchases.quantity),
                price:purchases.price,
                datestart:this.dateString(),
                dateend:this.dateString(this.datePlusDay(7)),
                datepurchase:this.dateString(),
            }
        },
        //vérification du prix avec le transport
        shipping(ulidMyOrders){
            this.$bvModal.hide('buy')
            this.$bvModal.show('shipping')
            axios.get(`myorders/shipping/${ulidMyOrders}`,{
                headers:{
                    'X-AUTH-TOKEN':localStorage.getItem('token')
                }
            })
            .then(resShipping=> {
                console.log({resShipping})
                this.shippings=resShipping.data
            })
            .catch(errShipping => console.log(errShipping))
        },
        cancelshipping(){
            this.$modal.hide('shipping')
        },
        validateMyorder() {
            this.loadermyinfo=true
            axios.put(`myorders/validate/${this.ulidMyOrders}`,{
                 deliveryinfos:{
                    "street1":this.line1,
                    "city":this.city,
                    "postal_code":this.postal_code,
                    "state":this.state,
                    "country":this.country
                    },
                status:"En cours",
                // "Sur Place- ULID" : 01FWNRJKJ9K3RKXEAV1BYFH5TZ
                shipping_provider: this.deliver ? this.selectedshipping['ulid'] :process.env.VUE_APP_ON_THE_SPOT,
                success_url:process.env.VUE_APP_SUCCESS_URL,
                cancel_url:process.env.VUE_APP_ERROR_URL,
                mode:"payment"
                },
                { withCredentials: true })
            .then(resValidateMyorder => {
                resValidateMyorder
                this.$store.dispatch('removeProductToCart')
                this.ulidMyOrders=''
                window.location.href = resValidateMyorder.data.url;
                console.log('Un mail a été envoyé',resValidateMyorder)
            })
            .catch(errValidateMyorder => console.log(errValidateMyorder))
        },
        deleteMyorders(){
            this.loadershow = true
            if(!this.ulidMyOrders){
                return
            }
            axios.delete(`myorders/${this.ulidMyOrders}`)
            .then(resDeleteMyorders=>{
                console.log(resDeleteMyorders)
                alert('Achat annulé')
                this.loadershow = false
            })
            .catch(errDeleteMyorders=>console.error({errDeleteMyorders}))
            
        },
        removeProductToCart(product){
            this.$swal({
            title: 'Êtes-vous sûr?',
            text: 'Voulez-vous supprimer ce produit ?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, Supprimer',
            cancelButtonText: 'Annuler',
        })
        .then((result) => {
            if (result.value) {
            //remove product in vuex
            this.$store.dispatch('removeProductToCart')
            //remove product in cart
            this.cart.splice(this.cart.indexOf(product), 1);
                this.$swal(
                'Supprimé!',
                'Le produit a bien été supprimé',
                'success'
                )          
            }
        })
        },
        Createcard(){
            //load service to vuex
            this.$store.state.cartproduct.products.forEach(element => {
                //cardEl data model for cart
                // let cardEl = { }
                // cardEl = element
                //populate cart
                element.totalp = element.quantity * (element.price * 1.2)
                this.cart.push(element)
            });
        },
        dateString(mydate){
            var stringdate;
            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
        digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
        },
        getMyInfo(){
            this.loadermyinfo = true
            axios.get("myinfos")
                .then(
                    resmyinfo => {
                        this.setdata(resmyinfo.data)
                        this.loadermyinfo = false
                        
                    }
                )
                .catch(
                    errmyinfo => {
                        console.log(errmyinfo)
                        this.loadermyinfo = false
                    }
                )
        },
        setdata(info){
            this.line1 = info.mainaddress.street1;                       
            this.line2 = info.mainaddress.street2;
            this.city = info.mainaddress.city;
            this.state = info.mainaddress.state;
            this.country = info.mainaddress.country;
            this.postal_code = info.mainaddress.zip;
            this.name =info.firstname + ' ' + info.lastname;
        },
        closeBuyModal(){
            this.$modal.hide('buy')
        },
        instagram(){
            window.location.href="https://www.instagram.com/monechappebelle81/"
        },
        facebook(){
            window.location.href="https://www.facebook.com/monechappeebelle81/"
        },
        datePlusDay(nbrofday,date){
            if (!date) {
                date = new Date();
            }
            date =date.setDate(date.getDate() + nbrofday)
            date =new Date(date)
            return date
        }
    },
    mounted(){
        this.Createcard()
        // this.$store.commit('SET_SERVICES')
    },
    beforeCreate() {
        // mettre X-Auth-token sur toutes les requêtes de la page
        axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('token');
    }
}
</script>

<style scoped>
.cream-background {
    position: relative;
    min-height: 30rem;
    background-image: url('../assets/images/import/cream.png');
    background-size: 80%;
    background-position:top -17rem right -4rem;
    background-repeat: no-repeat;
}
.mobile img {
    width: 100%;
}
.construct{
    background:#b89760;
    color: #fff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: absolute;
    right: 21px;
    top: 3px;
    /* padding-top: 25px; */
    font-size: 24px;
}
</style>